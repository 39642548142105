import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';
import { catchAndCallThrough } from '@studiohyperdrive/rxjs-utils';
import { first, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UserTargetCodeEntity } from '@cjm/shared/types';
import { LoginModalComponent, ModalDialogService } from '@cjm/shared/ui/modal';
import { catchWithSnackBar, SnackBarService } from '@cjm/shared/ui/toast';

import { I18nKeys } from '../../../i18n';
import { ProductApiService } from '../product-api.service';

@Injectable()
export class FavoriteProductsService {
	public readonly actionInProgress: WritableSignal<string | number> = signal<string | number>(null);

	private readonly i18nKeys: typeof I18nKeys = I18nKeys;

	private readonly modalService: ModalDialogService = inject(ModalDialogService);
	private readonly snackBarService: SnackBarService = inject(SnackBarService);
	private readonly i18nService: NgxI18nService = inject(NgxI18nService);
	private readonly apiService: ProductApiService = inject(ProductApiService);

	/**
	 * setProductAsFavorite
	 *
	 * The setProductAsFavorite method will mark a product as favorite for current user's association.
	 *
	 * @param productId - The ID of the product to be marked as favorite
	 *
	 */
	public setProductAsFavorite(productId: string | number): Observable<unknown> {
		if (!productId) {
			return of(null);
		}

		this.actionInProgress.set(productId);

		return this.apiService.setProductAsFavorite(productId).pipe(
			// Only proceed the first time.
			first(),
			catchAndCallThrough(() => {
				this.actionInProgress.set(null);
			}, 'throw'),
			catchWithSnackBar(
				this.snackBarService,
				{
					title: this.i18nService.getTranslation(this.i18nKeys.SnackBars.ErrorTitle)
				},
				'throw'
			),
			tap(() => {
				this.actionInProgress.set(null);
			})
		);
	}

	/**
	 * removeProductFromFavorites
	 *
	 * The removeProductFromFavorites method will unmark a product as favorite for current user's association.
	 *
	 * @param productId - The ID of the product to be unmarked as favorite
	 */
	public removeProductFromFavorites(productId: string | number): Observable<unknown> {
		if (!productId) {
			return of(null);
		}

		this.actionInProgress.set(productId);

		return this.apiService.removeProductFromFavorites(productId).pipe(
			// Only proceed the first time.
			first(),
			catchAndCallThrough(() => {
				this.actionInProgress.set(null);
			}, 'throw'),
			catchWithSnackBar(
				this.snackBarService,
				{
					title: this.i18nService.getTranslation(this.i18nKeys.SnackBars.ErrorTitle)
				},
				'throw'
			),
			tap(() => {
				this.actionInProgress.set(null);
			})
		);
	}

	/**
	 * showLoginModal
	 *
	 * The showLoginModal method will open the login modal to inform a user that
	 * they need to be logged in as an association to toggle a product as favourite.
	 *
	 */
	public showLoginModal(targetCode: UserTargetCodeEntity, callbackUrl: string = '/'): void {
		const modal = this.modalService.openModal(LoginModalComponent);

		// Set the modal title based on the user's target code
		// User is not logged in
		if (!targetCode) {
			modal.component.title = this.i18nService.getTranslation(
				this.i18nKeys.Products.Modals.FavouriteProductsLogin.Title.NotLoggedIn
			);
			modal.component.confirmButton = {
				text: this.i18nService.getTranslation(
					this.i18nKeys.Products.Modals.FavouriteProductsLogin.Buttons.LogIn.Text
				),
				title: this.i18nService.getTranslation(
					this.i18nKeys.Products.Modals.FavouriteProductsLogin.Buttons.LogIn.Title
				)
			};
		}
		// User is logged in as a civilian
		if (targetCode === UserTargetCodeEntity.CIVILIAN) {
			modal.component.title = this.i18nService.getTranslation(
				this.i18nKeys.Products.Modals.FavouriteProductsLogin.Title.AsCivilian
			);
			modal.component.confirmButton = {
				text: this.i18nService.getTranslation(
					this.i18nKeys.Products.Modals.FavouriteProductsLogin.Buttons.SwitchAccount.Text
				),
				title: this.i18nService.getTranslation(
					this.i18nKeys.Products.Modals.FavouriteProductsLogin.Buttons.SwitchAccount.Title
				)
			};
		}
		// User is logged in as a business
		if (
			targetCode === UserTargetCodeEntity.ECONOMIC_ACTOR ||
			targetCode === UserTargetCodeEntity.FLEMISH_GOVERNMENT ||
			targetCode === UserTargetCodeEntity.LOCAL_GOVERNMENT
		) {
			modal.component.title = this.i18nService.getTranslation(
				this.i18nKeys.Products.Modals.FavouriteProductsLogin.Title.NotAsAssociation
			);
			modal.component.confirmButton = {
				text: this.i18nService.getTranslation(
					this.i18nKeys.Products.Modals.FavouriteProductsLogin.Buttons.SwitchAccount.Text
				),
				title: this.i18nService.getTranslation(
					this.i18nKeys.Products.Modals.FavouriteProductsLogin.Buttons.SwitchAccount.Title
				)
			};
		}

		modal.component.text = this.i18nService.getTranslation(
			this.i18nKeys.Products.Modals.FavouriteProductsLogin.Text
		);
		modal.component.cancelButton = {
			text: this.i18nService.getTranslation(
				this.i18nKeys.Products.Modals.FavouriteProductsLogin.Buttons.Cancel.Text
			),
			title: this.i18nService.getTranslation(
				this.i18nKeys.Products.Modals.FavouriteProductsLogin.Buttons.Cancel.Title
			)
		};
		modal.component.loginCallback = callbackUrl;
	}
}
